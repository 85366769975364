.App {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.main-container {
  margin-top: 117px;
}
a {
  color: var(--app-black);
  text-decoration: none;
}
ul {
  list-style-type: none;
}