@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&family=Roboto:wght@100;300;400;500;700&display=swap');

:root {
  --app-blue: #3384DE;
  --app-pink: #F1224F;
  --app-black: #222222;
  --app-semi-black: #666666;
  --app-font-family: 'Roboto', sans-serif;
  --app-border-color: #d4d4d4;
  --app-hover-black: #4e4e4e;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--app-font-family);
  background-repeat: no-repeat;
}